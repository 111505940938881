<div class="container-fluid p-0" *ngIf="userInfo">
<div class="row col-md-12 p-0 m-0">
  <div><app-nav-bar></app-nav-bar></div>
  <div class="main-blk">
    <div id="main">
      <div *ngIf="">
        Name: {{name}}<br/>
        Username: {{username}}
      </div>
     <div class="page-blk">
      <router-outlet></router-outlet>
     </div>
    </div>
  </div>
</div>
</div>

<div id="Loader">
  <div class="overlay">
    <div class="overlay__inner">
        <div class="overlay__content"><span class="spinner"></span></div>
    </div>
  </div>
</div>
