import { Injectable } from '@angular/core';
import * as Msal from 'msal';
import { Observable } from 'rxjs/Observable';
import { environment } from '../environments/environment';
import { MsalService } from '@azure/msal-angular';
@Injectable({
    providedIn: 'root'
})

export class MsalUserService {
    private accessToken: any;
    public clientApplication: Msal.UserAgentApplication = null;
    constructor(private authService: MsalService) {
        this.clientApplication = new Msal.UserAgentApplication(
            environment.clientId,
            'https://login.microsoftonline.com/cb5d3e9b-b11e-47c2-af23-b095fdf3da17',
            this.authCallback,
            {
                storeAuthStateInCookie: true,
                // cacheLocation: 'localStorage' ,
            });
    }

    public GetAccessToken(): Observable<any> {
        if (sessionStorage.getItem('msal.accessToken') !== undefined && sessionStorage.getItem('msal.accessToken') != null) {
            this.accessToken = sessionStorage.getItem('msal.accessToken');
        }
        return this.accessToken;
    }

    public authCallback(errorDesc, token, error, tokenType) {
        if (token) {

        } else {
            console.log(error + ':' + errorDesc);
        }
    }

    public getCurrentUserInfo() {
        const user = this.clientApplication.getUser();
        user ? alert(user.name) : '';
    }

    public getAccount() {
        const user = this.clientApplication.getUser();
        return user;
    }

    public logout() {
        this.clientApplication.logout();
    }
}