import { environment } from '../../environments/environment';

const triplinkConfig = environment.tripLinkConfig;

export let CONFIG = {
    api: {
        license: triplinkConfig.licenseAPI,
        drr: triplinkConfig.drrAPI,
        tima: triplinkConfig.timaAPI,
        licenseNodejs: triplinkConfig.licenseNodejs
    },
    url: {
        getLicenseDetails: 'license/getall',
        addLicense: 'license/add',
        updateLicense: 'license/update',
        getLicenseHistory: '/license/getarchivebyid?licenseId=${}',
        getsecret: '/license/getsecret?clientId=${}',
        getalldrr: 'drrcompany/getall',
        updatedrrCompany: 'drrcompany/update',
        addDrrCompany: 'drrcompany/add',
        updateUser: 'drruser/update',
        addUser: 'drruser/add',
        getCompanyUsers: 'drruser/getusersbyid?companyId=${}',
        getTimaUsers: 'getAllTIMAUser',
        updateTimaUser: 'updateUser',
        getTimaInstallations: 'getInstallations?timePeriod=${}',
        getYardListByCompany: 'yardsByCompany?cid=${}',
        createYard: 'createyardsinbulk',
        associateYards: 'assignYardsInBulk',
        companyList: 'companylist',
        containerList: 'containerList',
        devices_PO_List: 'PODevices',
        assignTIMASupervisor: 'assignTIMASupervisor'
    },

    ROUTE_URLS: {
        licenseDetails: '/license',
        editLicenseDetails: '/license/license-details',
        licenseHistory: '/license/license-history',
        companies: '/license/companies',
        timaUsers: '/license/TIMAUsers',
        timaReports: '/license/TIMAReports',
        yardsManagement: '/license/TIMAYardsManagement',
        yardsCreation: '/license/TIMAYardsCreation',
        containerList: '/license/ContainerList',
        devices_PO_List: '/license/Devices_PO_List'
    },
};

