import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { MsalGuard } from '@azure/msal-angular';


const routes: Routes = [

  // {
  //   path: '',
  //   component: AppComponent,
  //   // canActivate: [MsalGuard],
  //   pathMatch: 'full',
  //   redirectTo: 'license',
  // },
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   redirectTo: 'license',
  // },
  { path: '', redirectTo: 'license', pathMatch: 'full', canActivate: [MsalGuard] },

  {
    path: 'license',
    canActivate: [MsalGuard],
    loadChildren: () => import('src/app/license/license.module').then(m => m.LicenseModule),

    // loadChildren: () => import('./license.module').then(m => m.LicenseModule)
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
