import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor() { }

  show() {
    const loaderObj = document.getElementById('Loader').style;
    loaderObj.display = 'block';
  }
  hide() {
    const loaderObj = document.getElementById('Loader').style;
    loaderObj.display = 'none';
  }
}
