import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClientXsrfModule,
} from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { MSALConfigFactory } from './msal-config';
// import { MSAL_CONFIG, MSAL_CONFIG_ANGULAR, MsalGuard, MsalModule } from '@azure/msal-angular';
import { MsalService } from '@azure/msal-angular';
import { MSALAngularConfigFactory } from './msal-angular-config';
import { BroadcastService } from '@azure/msal-angular';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { PagerModule, GridModule } from '@syncfusion/ej2-angular-grids';
import { environment } from 'src/environments/environment';
import { MsalModule } from '@azure/msal-angular';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent
  ],
  imports: [
    // MsalModule.forRoot({
    //         clientID: environment.clientId,
    //         authority: 'https://login.microsoftonline.com/' + environment.tenantId,
    //         redirectUri: environment.redirectUrl
    //       }),
    MsalModule.forRoot(
      {
        clientID: environment.clientId,
        authority: 'https://login.microsoftonline.com/' + environment.tenantId,
        redirectUri: environment.redirectUrl,
        cacheLocation: 'localStorage',
        //  storeAuthStateInCookie: !isIE,
        popUp: false,
        consentScopes: [
          'user.read',
          'openid',
          'profile',
        ],
        unprotectedResources: [],
        protectedResourceMap: [
          ['https://graph.microsoft.com/v1.0/' + environment.tenantId, ['user.read']]
        ],
      }),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserModule,
    CommonModule,
    PagerModule,
    GridModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),
  ],
  providers: [],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
