import { Component, OnInit, OnDestroy } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { BroadcastService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import { LoaderService } from './services/loader.service';
import { MsalUserService } from '../app/msaluser.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CONFIG } from './core/config';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'my-app';
  name: string;
  username: string;
  userInfo;
  private subscription: Subscription;
  navigationSubscription;

  constructor(
    private msalUserService: MsalUserService,
    private msalService: MsalService,
    private loaderService: LoaderService,
    public router: Router,
    private broadcastService: BroadcastService) {

      this.navigationSubscription = this.router.events.subscribe((e: any) => {
        if (e instanceof NavigationEnd && e.url === '/'){
          this.router.navigate([CONFIG.ROUTE_URLS.licenseDetails]);
        }
      });
     }

  ngOnInit(): void {
    this.loaderService.hide();
    const reqArr = [environment.appId + '/access_as_user'];
    // const reqArr = [environment.appId + '/.default'];
    this.msalService.acquireTokenSilent(reqArr).then( (accessTokenResponse) => {
      sessionStorage.setItem('msal.accessToken', accessTokenResponse );
      const account = this.msalUserService.getAccount();
      if (account) {
        this.userInfo = account;
        sessionStorage.setItem('userInfo', JSON.stringify(account));
      }
    }, (error) => {
      if(error){
        // alert(error);
        // this.msalUserService.logout();
        this.msalUserService.GetAccessToken();

      }
      
    });
  }
  ngOnDestroy() {
    this.broadcastService.getMSALSubject().next(1);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
