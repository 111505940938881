// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  clientId: 'c3112c67-7e66-4c59-bd75-0fae25f69fc2',
  tenantId: 'cb5d3e9b-b11e-47c2-af23-b095fdf3da17',
  appId: 'abf2a657-ba94-4030-9217-9e5a8750a84c',
  authority: 'https://login.microsoftonline.com/cb5d3e9b-b11e-47c2-af23-b095fdf3da17/',

  /** Alpha  */
  // redirectUrl: 'https://alpha-triplinklicense-carrier.azurewebsites.net',

  /** Prod URL */
  redirectUrl: 'https://license.triplink.carrier.com',

  /** For local environmnet */
  // redirectUrl: 'http://localhost:4200/',

  tripLinkConfig: {
    /** Dev URLS */
    // licenseAPI: 'https://triplinklicenseapidev.azurewebsites.net/',
    // drrAPI: 'https://triplinklicensedrrwebapidev.azurewebsites.net',
    // timaAPI: 'https://triplinklicensetimaservicesdev.azurewebsites.net',
    // licenseNodejs: 'https://licensenodejs.azurewebsites.net/api'

    /** Prod URLS */
    drrAPI: 'https://drr-triplink-carrier.azurewebsites.net',
    licenseAPI: 'https://api-license-triplink-carrier.azurewebsites.net/',
    timaAPI: 'https://triplinklicensetimaservicesprd.azurewebsites.net',
    licenseNodejs: 'https://licensenodejsprod.azurewebsites.net/api'


  },
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
