<div id="mySidenav" class="sidenav">
  <div class="navbar-logo">
    <img src="assets/images/TripLink-Logo.png" class="logo-img" [ngClass]="{'mrg-collapsed' : !isMenuCollapsed}">
    <ul class="nav-sidebar">
      <li class="sidebar-list-item">
        <a class="color-ff nav-border-bottom sidebar-list-link" [ngClass]="{'hamburger-icon' : isMenuCollapsed}"
          (click)="toggleMenu()" href="javascript:;" style="color: #fff !important">
          <img class="w-30" [src]="menuIconImg" [ngClass]="{ collapsed: isMenuCollapsed, 'nav-back-icon' :
      !isMenuCollapsed}" id="collapseImg" />
          <span class="initial-position menu-item-text pl-4">TripLINK Licenses
          </span>
        </a>
      </li>
      <li class="sidebar-list-item">
        <a rel='nofollow' href="javascript:void(0);" style="cursor: initial;"
          class=" nav-border-bottom sidebar-list-link user-name-blk">
          <div class="row line-height-10">
            <div class=" position-relative pl-user-icon">
              <img src="assets/images/profile.svg" class="mt-2 user-image" />
              <span class="user-active-icon"></span>
              <div class="pl-4">
                <div class="">Welcome</div>
                <div class="user-name" title="{{userObj.userName}}">{{userObj.name}}</div>
              </div>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
  <div class="navbar-menu">
    <ul class="nav-sidebar">
      <li class="sidebar-list-item">
        <a class="sidebar-list-link" (click)="navigateToLicense()" title="ML5 Licenses">
          <img src="assets/images/license-menu.svg" class="nav-icon" />
          <span class="menu-item-text pl-4">ML5 Licenses
          </span>
        </a>
      </li>

      <li class="sidebar-list-item">
        <a class="sidebar-list-link" (click)="navigateToCompanies()" title="Digital Repair Recorder">
          <img src="assets/images/office-building.svg" class="p-1 filter-white nav-icon" />

          <span class="menu-item-text pl-4">Repair Registration
          </span>
        </a>
      </li>

      <li class="sidebar-list-item">
        <a class="sidebar-list-link" title="Telematics Device Management">
          <img src="assets/images/users-group.svg" class="p-1 filter-white nav-icon" />
          <span class="menu-item-text pl-4">Telematics Device Management
          </span>
        </a>
        <ul class="sidebar-sublist">
          <li class="sidebar-sublist-item">
            <a (click)="navigateToUsers()" title="TIMA Users">Users</a>
          </li>
          <li class="sidebar-sublist-item">
            <a (click)="navigateToReports()" title="Installation Info">Installation Info</a>
          </li>
          <li class="sidebar-sublist-item">
            <a (click)="navigateToYardsManagement()" title="Yards Management">Yards Management</a>
          </li>
          <li class="sidebar-sublist-item">
            <a (click)="navigateToContainerList()" title="Container List">Container List</a>
          </li>
          <li class="sidebar-sublist-item">
            <a (click)="navigateToDevicesAndPOList()" title="Devices & PO List">Devices & PO List</a>
          </li>
        </ul>
      </li>

      <li class="sidebar-list-item">
        <a class="sidebar-list-link" (click)="logout()" title="Logout">
          <img src="assets/images/logout.svg" class="nav-icon" />
          <span class="menu-item-text pl-4">Logout
          </span>
        </a>
      </li>

      <li class="sidebar-list-item">
        <a rel='nofollow' href="javascript:void(0);" class="sidebar-list-link user-name-blk">
          <div class="row line-height-10">
            <div class=" position-relative pl-user-icon">
              <img src="assets/images/transicold-logo.svg" class="mt-2 user-image" />
              <div class="pl-4">
                <div class="">Carrier Corporation</div>
                <div class="user-name">2020</div>
              </div>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</div>

<!-- Commented Codes -->
<!-- <img src="assets/images/ML5_logo.svg" class="logo-img" [ngClass]="{'mrg-collapsed' : !isMenuCollapsed}"> -->

<!-- <a class="sidebar-list-link">
      <img src="assets/images/Dashboard.svg" class="nav-icon" />
      <span class="menu-item-text pl-4">Dashboard
      </span>
    </a> -->
<!-- <a class="sidebar-list-link">
      <img src="assets/images/notification.svg" class="nav-icon" />

      <span class="menu-item-text pl-4">Notifications
        <span class="notification-badge"></span>
      </span>
    </a> -->
<!-- <a class="sidebar-list-link">
      <div>
        {{userObj.userName}}
      </div>
    </a> -->